import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PricesWidget from './PricesWidget';
import LanguageSelector from './LanguageSelector';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavbarChild from './NavbarChild';
import NavbarParent from './NavbarParent';

export default function HeaderBox() {
    const { t } = useTranslation(["navbar"]);

    const [navbarOpen, setNavbarOpen] = React.useState(false);

    const page_structure = [
        {head: '/coins', title: "muenzen_medaillen",
            subpages: [
                {title: 'world_of_numismatics', link: '/coins/world-of-numismatics'}, 
                {title: "catalogue", link : '/coins/catalogs'},
                {title: "mgm_index", link : '//www.mgmindex.de'},
                {title: "unknown_pieces", link: '/coins/unknown-pieces'},
                {title: "contributions", link: '/coins/fachbeitraege'},
                {title: "roemische_muenzen", link: '/coins/roemische-muenzen'}
        ]},
        { head: '//www.mgm-schmuck.de/', title: "schmuck", 
            subpages: [
                {title: 'goldsmith', link: '//www.mgm-schmuck.de/goldschmiede/anfertigung'}, 
        ]},
        { head: '/gold', title: "edelmetalle",
            subpages: [
                {title: 'overview', link: '/gold/overview'},
                {title: 'verkauf', link: '/gold/sale'},
                {title: 'ankauf', link: '/gold/purchase'}
        ]},
        { head: '/other', title: 'weitere',
             subpages: [
                {title: 'numismatik', link: '/other/literature'}, 
                {title: 'phaleristics', link: '/other/phaleristics'},
                {title: 'treasure_trove', link: '/other/treasure_trove'},
                {title: 'cards', link: '/other/playing_cards'}
        ]},
        { head: '/acquisition-and-valuation', title: 'acquisition',
            subpages: [
                {title: 'coins', link: '/acquisition-and-valuation/coins'},
                {title: 'schmuck', link: '//www.mgm-schmuck.de/ankaufundschaetzung'},
                {title: 'edelmetalle', link: '/gold/purchase'}
        ]},
        { head: '/service', title: 'service', 
            subpages: [
                {title: 'contact', link: '/service/contact'},
                {title: 'faq', link: '/service/faq'} ,
                {title: 'aboutus', link: '/service/about-us'},
                {title: 'pawn_shop', link: '//www.mgmpfand.de'}
        ]}
    ]

    return (
        <header className="flex flex-col">
            <div className="grid grid-cols-3 bg-gray-100 py-1 px-5p text-xs md:text-sm fg-gray-700">
                <div className="justify-self-start align-self-center"><LanguageSelector /></div>
                <div className="justify-self-center"><PricesWidget /></div>
            </div>
            <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row flex-nowrap px-5p py-2 items-center justify-between ">
                <div className="w-full flex md:block md:w-auto md:flex-initial md:flex-shrink py-2 justify-between items-center">
                    <Link to="/" >
                        <img className="w-60" src="/images/logo.svg" alt="mgm-logo" />
                    </Link>
                    <div></div>

                    <button
                        className="transition duration-300 ease-in-out relative flex md:hidden focus:outline-none"
                        type="button"
                        onClick={() => setNavbarOpen(!navbarOpen)}>
                        <FontAwesomeIcon color="black" size="lg" icon="bars" />
                    </button>
                </div>

                <nav
                    className={
                        "flex flex-wrap items-center md:justify-center justify-between px-2 py-3 w-full md:flex" +
                        (navbarOpen ? " flex" : " hidden")
                    }
                >
                    <ul className="flex flex-col md:flex-row list-none w-full md:w-auto md:tracking-widest text-justify">
                        {page_structure.map((menuitem, idx) => {
                            return <NavbarParent key={idx} link={menuitem.head} title={t(menuitem.title)} external={menuitem.head.includes('//')}>
                                {menuitem.subpages.map((subitem, idx2) => {
                                    return <NavbarChild key={idx2} link={subitem.link} title={t(subitem.title)} external={subitem.link.includes('//')} />
                                })}
                            </NavbarParent>
                        })}
                    </ul>
                </nav>
                <div className='w-60'></div>
            </div >
        </header >
    );
}