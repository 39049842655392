import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";
import { useTranslation } from "react-i18next";
import TitleBar from "../components/TitleBar";
import CarouselBar from "../components/CarouselBar";
import Timeline from "../components/Timeline";
import CardTile from "../components/CardTile";

export default function WorldOfNumismatics(props) {
  const { t } = useTranslation(["numismatic_world_screen"]);
  const timeline_items = [...Array(12)].map((e, i) => {
    return {
      title: t("timeline" + (i + 1) + "_title"),
      description: t("timeline" + (i + 1) + "_text"),
      image: "/images/timelines/coin_history/" + (i + 1) + ".jpg",
      year: t("timeline" + (i + 1) + "_year")
    };
  });

  const carousel_items = [
    {
      image: "/images/coins-crop.jpg",
      title: t("carousel1_h"),
      text: t("carousel1_t"),
    },
  ];
  return (
    <div className="px-5p">
      <CarouselBar items={carousel_items} />
      <hr></hr>
      <TitleBar
        title={t("title")}
        description={t("description", {
          interpolation: { escapeValue: false },
        })}
      />
      <div className="flex flex-col">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-x-32 md:px-20 pt-12">
          <CardTile
            title={t("topic1_h")}
            link={"//shop.muenzgalerie.de"}
            image={"/images/coinsThumb.jpg"}
            description={t("topic1_t")}
            external={true}
          />
          <CardTile
            title={t("topic2_h")}
            link={"/coins/catalogs"}
            image={"/images/literaturThumb.jpg"}
            description={t("topic2_t")}
          />
          <CardTile
            title={t("topic3_h")}
            link={"/acquisition-and-valuation/coins"}
            image={"/images/ankaufThumb.jpg"}
            description={t("topic3_t")}
          />
        </div>
      </div>
      <div className="divide-y space-y-2">
        <div className="w-full items-center justify-center flex flex-col md:flex-row ">
          <div className="md:w-full justify-center flex flex-col items-center p-4 h-full space-y-2 md:space-y-5 md:p-10">
            <div className="text-center w-full tracking-widest text-2xl md:text-3xl uppercase font-semibold">
              {t("timeline_h")}
            </div>
            <div className="whitespace-pre-line">{t("timeline_t")}</div>
          </div>
        </div>
      </div>
      <Timeline
        title="Geschichte der Numismatik"
        subtitle="Von der Antike bis zur Einführung des Euros"
        items={timeline_items}
      />
    </div>
  );
}
