import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../css/flickity.css";
import { Carousel } from "react-responsive-carousel";

export default function CarouselBar(props) {
  const { items } = props;
  return (
    <Carousel
      className="block -px-5p flickity-enabled"
      renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
        hasPrev && (
          <button
            onClick={clickHandler}
            className="flickity-button flickity-prev-next-button previous"
          >
            <svg className="flickity-button-icon" viewBox="0 0 100 100">
              <path
                d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
                className="arrow"
              ></path>
            </svg>
          </button>
        )
      }
      // flickity-button flickity-prev-next-button next
      renderArrowNext={(clickHandler, hasNext, labelNext) =>
        hasNext && (
          <button
            onClick={clickHandler}
            className="flickity-button flickity-prev-next-button next"
          >
            <svg className="flickity-button-icon" viewBox="0 0 100 100">
              <path
                d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
                className="arrow"
                transform="translate(100, 100) rotate(180) "
              ></path>
            </svg>
          </button>
        )
      }
      showArrows
      autoPlay={true}
      interval={8000}
      infiniteLoop={true}
      transitionTime={700}
      showThumbs={false}
      showStatus={false}
      showIndicators={items.length > 1 ? true : false}
    >
      {items.map((item, idx) => (
        <div
          key={idx}
          className="carousel-image"
          style={{
            backgroundImage: "url(" + item.image + ")",
            backgroundPosition: "center",
          }}
        >
          {item.link ? (
            <a href={item.link} target="_blank"  rel="noopener noreferrer">
              <div className="carousel-caption d-none d-md-block noselect">
                <h1>{item.title}</h1>
                <p>{item.text}</p>
              </div>
            </a>
          ) : (
            <div className="carousel-caption d-none d-md-block noselect">
              <h1>{item.title}</h1>
              <p>{item.text}</p>
            </div>
          )}
        </div>
      ))}
    </Carousel>
  );
}
