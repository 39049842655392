import React from "react";
import { useTranslation } from "react-i18next";

export default function Disclaimer() {
  const { t } = useTranslation(["disclaimer_screen"]);
  const order = [
      {id: 1, l: 7},
      {id: 2, l: 2}
    ]
  return (
    <div className="px-5p">
      <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">{t("disclaimer_h")}</h1>
      <div className="space-y-4 divide-y">
      {order.map((entry,idx) => {
            return (
              <div className="space-y-2" key={"c"+idx}> 
                <b>{"§"+entry['id']+" "+t("disclaimer" + entry['id'] + "_h")}</b>
                <div className="text-sm space-y-2">
                  {[...Array(parseInt(entry["l"]))].map((e, idx2) => {
                      return <p key={"p"+idx2}>{t("disclaimer" + entry['id'] + "_" + (idx2+1))}</p>
                  })}
                </div>
               </div>
            )})}
      </div>
    </div>
  )
}