import React from 'react'

export default function HalfImageHalfLinkDiv(props) {
    return (
        props.alignment === "right" ?
            <a rel="noreferrer" href={props.link} target="_blank">
                <div className="w-full items-center justify-center flex flex-col md:flex-row ">
                    <div className="md:w-1/3 justify-center flex flex-col items-center p-4 h-full space-y-2 md:space-y-5 md:p-10">
                        <div className="text-center w-full tracking-widest text-2xl md:text-3xl uppercase font-semibold">{props.title}</div>
                        {props.title && <hr className="w-full md:w-1/2" />}
                        <div className="whitespace-pre-line tracking-widest uppercase text-sm">{props.description}</div>
                    </div>
                    <div className="md:w-1/4  border border-gray-400">
                        <img className="rounded-lg" src={props.image} alt={props.title} />
                    </div>
                </div>
            </a>
            :
            <a rel="noreferrer" href={props.link} target="_blank">
                <div className="w-full items-center justify-center flex flex-col-reverse md:flex-row ">
                    <div className="md:w-1/4 border border-gray-400">
                        <img className="rounded-lg" src={props.image} alt={props.title} />
                    </div>
                    <div className="md:w-1/3 justify-center flex flex-col items-center p-4 h-full space-y-2 md:space-y-5 md:p-10">
                        <div className="text-center w-full tracking-widest text-2xl md:text-3xl uppercase font-semibold">{props.title}</div>
                        {props.title && <hr className="w-full md:w-1/2" />}
                        <div className="whitespace-pre-line tracking-widest uppercase text-sm">{props.description}</div>
                    </div>
                </div>
            </a>
    )
}
