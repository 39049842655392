import { Link, Redirect, Route } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import FooterBox from './components/FooterBox';
import HeaderBox from './components/HeaderBox';
import HomeScreen from './screens/HomeScreen';
import AcquisitionAndValuation from './screens/AcquisitionAndValuation';
import AboutUs from './screens/AboutUs';
import FAQScreen from './screens/FAQScreen';
import WorldOfNumismatics from './screens/WorldOfNumismatics';
import CatalogScreen from './screens/Catalog_Screen';
import UnknownPieces from './screens/UnknownPieces';
import SpecialistArticles from './screens/SpecialistArticles';
import LiteratureScreen from './screens/Literature_Screen';
import PhaleristicsScreen from './screens/Phaleristics_Screen';
import TreasureTroveScreen from './screens/TreasureTrove_Screen';
import PlayingCardsScreen from './screens/PlayingCards_Screen';
import ContactScreen from './screens/Contact_Screen';
import Legal from './screens/Legal';
import ImprintScreen from './screens/ImprintScreen';
import Disclaimer from './screens/Disclaimer_Screen';
import { useGoogleAnalytics, sendGA } from './components/useGoogleAnalytics';
import TermsScreen from './screens/TermsScreen';
import GoldScreen from './screens/GoldScreen';
import GoldSaleScreen from './screens/GoldSaleScreen';
import GoldPurchaseScreen from './screens/GoldPurchaseScreen';
import RoemischeMuenzenScreen from './screens/RoemischeMuenzenScreen';

export default function Routes() {
  const handleAcceptCookies = () => {
    sendGA(window.location)
  }
  useGoogleAnalytics()
  const { t } = useTranslation(["cookie_consent"]);

  return (
    <div className="flex flex-col font-source min-h-full	">
      <div className="border-b border-gray-200">
        <HeaderBox />
        <CookieConsent onAccept={handleAcceptCookies} flipButtons declineButtonStyle={{ background: "rgb(53,53,53)" }} declineButtonText={t("decline")} buttonText={t("accept")} enableDeclineButton>{t("text")} <Link className="underline text-blue-500" to="/legal-psds">{t("more")}</Link></CookieConsent>
      </div>
      <main className="pb-8 pt-2 min-h-full flex-grow">

        <Route path="/coins/world-of-numismatics" component={WorldOfNumismatics}></Route>
        <Route path="/coins/roemische-muenzen" component={RoemischeMuenzenScreen}></Route>
        <Route path="/coins/catalogs" component={CatalogScreen}></Route>
        <Route path="/coins/unknown-pieces" component={UnknownPieces}></Route>
        <Route path="/coins/fachbeitraege" component={SpecialistArticles}></Route>
        <Route path="/gold/overview" component={GoldScreen} />
        <Route path="/gold/sale" component={GoldSaleScreen} />
        <Route path="/gold/purchase" component={GoldPurchaseScreen} />
        <Route path="/other/literature" component={LiteratureScreen}></Route>
        <Route path="/other/phaleristics" component={PhaleristicsScreen}></Route>
        <Route path="/other/treasure_trove" component={TreasureTroveScreen}></Route>
        <Route path="/other/playing_cards" component={PlayingCardsScreen}></Route>
        <Route path="/acquisition-and-valuation/coins" component={AcquisitionAndValuation}></Route>
        <Route path="/service/contact" component={ContactScreen} />
        <Route path="/service/faq" component={FAQScreen}></Route>
        <Route path="/service/about-us" component={AboutUs}></Route>
        <Route path="/terms" component={TermsScreen} />
        <Route path="/imprint" component={ImprintScreen}></Route>
        <Route path="/legal-psds" component={Legal}></Route>
        <Route path="/disclaimer" component={Disclaimer}></Route>
        <Route exact path="/katalog">
          <Redirect to="/coins/catalogs" />
        </Route>
        <Route exact path="/kataloge">
          <Redirect to="/coins/catalogs" />
        </Route>
        <Route path="/" component={HomeScreen} exact></Route>
      </main>
      <FooterBox />
    </div>
  );
}


