import React from "react";
import { useTranslation } from "react-i18next";
import TitleBar from "../components/TitleBar";
import Question from "../components/Question";
import CarouselBar from "../components/CarouselBar";

export default function AcquisitionAndValuation(props) {
  const { t } = useTranslation(["acquisition_screen"]);
  const carousel_items = [
    {
      image: "/images/head-pfand-gold-crop.jpg",
      link: "",
      title: t("carousel1_h"),
      text: t("carousel1_t"),
    },
  ];
  return (
    <div className="px-5p">
      <CarouselBar items={carousel_items} />
      <TitleBar title={t("title")} description={t("description")} />
      <div className="divide-y space-y-2">
        <div className="pb-2">
          <p className="font-semibold py-5">{t("guide1_h")}</p>
          <p>{t("guide1_t")}</p>
        </div>
        <div className="pb-2">
          <p className="font-semibold py-5">{t("guide2_h")}</p>
          <p>{t("guide2_t")}</p>
        </div>
        <div className="pb-2">
          <p className="font-semibold py-5">{t("guide3_h")}</p>
          <div>
            <p>{t("guide3_t")}</p>
            <Question
              id={"check1"}
              question={t("conservation1_h")}
              answer={t("conservation1_t")}
            />
            <Question
              id={"check2"}
              question={t("conservation2_h")}
              answer={t("conservation2_t")}
            />
            <Question
              id={"check3"}
              question={t("conservation3_h")}
              answer={t("conservation3_t")}
            />
            <Question
              id={"check4"}
              question={t("conservation4_h")}
              answer={t("conservation4_t")}
            />
            <Question
              id={"check5"}
              question={t("conservation5_h")}
              answer={t("conservation5_t")}
            />
            <Question
              id={"check6"}
              question={t("conservation6_h")}
              answer={t("conservation6_t")}
            />
            <br />
            <a
              href={
                "//www.mgmindex.de/MuenzenSammeln:Erhaltungsgrade_von_M%C3%BCnzen"
              }
              className="content-center"
            >
              <h3 className="text-red-400 text-l">{t("guide3_t2")}</h3>
            </a>
          </div>
        </div>
        <div className="pb-2">
          <p className="font-semibold py-5">{t("guide4_h")}</p>
          <p>{t("guide4_t")}</p>
        </div>
      </div>
    </div>
  );
}
