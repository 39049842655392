import React from "react";
import { useTranslation } from "react-i18next";
import TitleBar from "../components/TitleBar";
import CarouselBar from "../components/CarouselBar";
import CardTile from "../components/CardTile";

export default function GoldScreen(props) {
  const { t } = useTranslation(["gold_screen"]);
  const carousel_items = [
    {
      image: "/images/head-gold-1-crop.jpg",
      link: "",
      title: t("carousel1_h"),
      text: t("carousel1_t"),
    },
  ];
  return (
    <div className="px-5p">
      <CarouselBar items={carousel_items} />
      <TitleBar title={t("title")} description={t("description")} />

      <div className="h-full flex items-center flex-col space-y-2 place-content-center py-10 space-y-2">
        <p className="text-center italic space-y-4 divide-y flex flex-col">
          <span className="text-xl text-gray-700 tracking-widest font-semibold">
            {t("topic_h")}
          </span>
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-4 md:px-20 pt-12">
          <CardTile

            title={t("topic1_h")}
            link={"/gold/sale"}
            image={"/images/edelmetallankaufButton.jpg"}
            description={t("topic1_t")}
          />
          <div></div>
          <CardTile
            title={t("topic2_h")}
            link={"/gold/purchase"}
            image={"/images/edelmetallverkaufButton.jpg"}
            description={t("topic2_t")}
          />
        </div>
      </div>
    </div>
  );
}
