import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";
import CarouselBar from "../components/CarouselBar";
import TitleBar from "../components/TitleBar";

export default function RoemischeMuenzenScreen(props) {


    const carousel_items = [
        {
            image: "/images/coins-crop.jpg",
            title: "",
        },
    ];
    return (
        <div className="px-5p">
            <CarouselBar items={carousel_items} />
            <hr></hr>
            <TitleBar title={"Römische Münzen"}/>

            <div className="flex pt-4 flex-col space-y-4"><h2 className="pt-6 text-gray-700 font-bold text-2xl"> Welche römischen Münzen eignen sich zum Sammeln? </h2>

                <div className=" text-left leading-6 text-lg text-gray-700 pt-4">
                    Die Frage, welche römischen Münzen sich zum Sammeln eignen ist
                    einfach. Im Grunde eignen sich alle römischen Münzen zum
                    Sammeln. Jede römische Münze ist Zeuge lang vergangener Zeit.
                    Münzen aus der römischen Republik (bis zu Caesar und Augustus)
                    sind für Geschichte und Religion der Römer ungemein wichtig: Sie
                    zeigen Münzmeisternamen und viele Kultgegenstände und
                    Götterdarstellungen.
                    <br /><br />
                    Münzen der Kaiserzeit glänzen durch naturalistische Porträts der
                    römischen Herrscher. Auf den Rückseiten der Münzen finden sich
                    Allegorien, die das Regierungshandeln der Kaiser beleuchten.
                    <br /><br />
                    Man kann also auf verschiedene Art sammeln: römische Porträts,
                    römische Götter oder auch römische Propaganda.
                </div></div>

            <div className="flex pt-4 flex-col space-y-4"><h2 className="pt-6 text-gray-700 font-bold text-2xl"> Aus welcher Zeit stammen römische Münzen? </h2>
                <div className=" text-left leading-6 text-lg text-gray-700 pt-4">
                    Römische Münzen gibt es seit dem 3. Jahrhundert vor Christus:
                    schwere gegossene Bronzebarren oder runde klumpenförmige große
                    Münzen – nichts für ein Portemonnaie.
                    <br /><br />
                    Die Römer prägten seit ca. 220 v. Chr. nach griechischem Vorbild
                    Silbermünzen. Dadurch wurde das römische Geld deutlich
                    handlicher. Bis zum Ende des weströmischen Reiches im 5. Jh.
                    nach Christus wurden Münzen verschiedener Wertstufen nach
                    gleichen Grundsätzen ausgeprägt.
                    <br /><br />
                    Die oströmischen Kaiser setzten die Prägung fort mit anderen
                    Bildern und mit christlichen Motiven.
                    <br /><br />
                    Unser heutiges Geld geht letztlich auf die Formen des römischen
                    Geldes zurück, doch stehen erst auf byzantinischen Münzen
                    Wertangaben.
                </div></div>
            <div className="flex pt-4 flex-col space-y-4"><h2 className="pt-6 text-gray-700 font-bold text-2xl"> Aus welchem Material sind römische Münzen? </h2>
                <div className=" text-left leading-6 text-lg text-gray-700 pt-4">
                    Es gibt antike römische Münzen aus verschiedenen Metallen.
                    Hauptsächlich gibt es römische Münzen aus Gold, Silber, Bronze
                    und Kupfer.
                    <br /><br />
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-5 ">
                        <a target="_blank" href="//shop.muenzgalerie.de/product/muenzen-r-mer-hadrianus-117-138-aureus-119-138/YzxnNUXsKIBw" rel="noreferrer">
                            <div className="h-full max-w-2xl">
                                <div className="product border border-gray-200 flex bg-white flex-nowrap flex-col h-full justify-between p-2 md:p-5">
                                    <div className="flex flex-row justify-between pb-2">
                                        <div></div>
                                    </div>
                                    <div className="flex flex-row flex-nowrap justify-items-center justify-center items-center">
                                        <img className="object-contain w-auto md:48 lg:h-52 xl:h-80 2xl:h-96 " src="//shop.muenzgalerie.de/uploads/161099_0.jpg" alt="Römische_Goldmünze" />
                                    </div>
                                    <div>
                                        <div className="">
                                            <div className="flex space-x-2 items-center ">
                                                <div className="font-semibold">Römer</div>
                                            </div>
                                            <div className="text-sm ">Antoninus Pius, 138-161, Aureus, 153-154</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a target="_blank" href="//shop.muenzgalerie.de/product/muenzen-r-mer-elagabalus-218-222-denar/EbkOklENFrli" rel="noreferrer">
                            <div className="h-full max-w-2xl">
                                <div className="product border border-gray-200 flex bg-white flex-nowrap flex-col h-full justify-between p-2 md:p-5">
                                    <div className="flex flex-row justify-between pb-2">
                                        <div></div>
                                    </div>
                                    <div className="flex flex-row flex-nowrap justify-items-center justify-center items-center">
                                        <img className="object-contain w-auto md:48 lg:h-52 xl:h-80 2xl:h-96 " src="//shop.muenzgalerie.de/uploads/128341_0.jpg" alt="Römische_Silbermünze" />
                                    </div>
                                    <div>
                                        <div className="">
                                            <div className="flex space-x-2 items-center ">
                                                <div className="font-semibold">Römer</div>
                                            </div>
                                            <div className="text-sm ">Elagabalus, 218-222, Denar</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a target="_blank" href="//shop.muenzgalerie.de/product/muenzen-r-mer-gallienus-253-268-antoninian/kDAZogFalraS" rel="noreferrer">
                            <div className="h-full max-w-2xl">
                                <div className="product border border-gray-200 flex bg-white flex-nowrap flex-col h-full justify-between p-2 md:p-5">
                                    <div className="flex flex-row justify-between pb-2">
                                        <div></div>
                                    </div>
                                    <div className="flex flex-row flex-nowrap justify-items-center justify-center items-center">
                                        <img className="object-contain w-auto md:48 lg:h-52 xl:h-80 2xl:h-96 " src="//shop.muenzgalerie.de/uploads/128295_0.jpg" alt="Römische_Bronzemünze" />
                                    </div>
                                    <div>
                                        <div className="">
                                            <div className="flex space-x-2 items-center ">
                                                <div className="font-semibold">Römer</div>
                                            </div>
                                            <div className="text-sm ">Gallienus, 253-268, Antoninian</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a target="_blank" href="//shop.muenzgalerie.de/product/muenzen-r-mer-constans-333-350-ae-15-347-348/HKiJPcvDzodH" rel="noreferrer">
                            <div className="h-full max-w-2xl">
                                <div className="product border border-gray-200 flex bg-white flex-nowrap flex-col h-full justify-between p-2 md:p-5">
                                    <div className="flex flex-row justify-between pb-2">
                                        <div></div>
                                    </div>
                                    <div className="flex flex-row flex-nowrap justify-items-center justify-center items-center">
                                        <img className="object-contain w-auto md:48 lg:h-52 xl:h-80 2xl:h-96 " src="//shop.muenzgalerie.de/uploads/162367_vs.jpg" alt="Römische_Kupfermünze" />
                                    </div>
                                    <div>
                                        <div className="">
                                            <div className="flex space-x-2 items-center ">
                                                <div className="font-semibold">Römer</div>
                                            </div>
                                            <div className="text-sm ">Constans 333-350, AE 15, 347-348</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>

                    </div>
                </div></div>

            <div className="flex pt-4 flex-col space-y-4"><h2 className="pt-6 text-gray-700 font-bold text-2xl"> Gibt es gefälschte römische Münzen?</h2>
                <div className=" text-left leading-6 text-lg text-gray-700 pt-4">
                    Münzen und Geld wurde bereits in der Antike gefälscht. In der
                    Renaissance wurde römisches Geld bzw. römische Münzen gesammelt
                    und imitiert, weil es so schön und vorbildhaft war. Vor allem in
                    Form von großen Bronzemedaillen.
                    <br /><br />
                    Padua war ein Zentrum der Antikenimitation im 15. und 16.
                    Jahrhundert – die dort hergestellten römischen Fantasiemünzen
                    heißen deshalb heute “Paduaner”. Gelegentlich stößt man auch auf
                    Imitationen zu Souvenierzwecken, doch steckt auch oft
                    betrügerische Absicht dahinter. An den Badeorten des Mittelmeers
                    blühten früher die Fälscherwerkstätten.
                </div></div>

            <div className="flex pt-4 flex-col space-y-4"><h2 className="pt-6 text-gray-700 font-bold text-2xl"> Seit wann sammelt man römische Münzen? </h2>
                <div className=" text-left leading-6 text-lg text-gray-700 pt-4">
                    Seit der Renaissance im 14. Jahrhundert sammelt man Münzen.
                    Ausschlaggebend waren hier die ersten Ausgrabungen in
                    Norditalien. Damalige Sammlungen von antiken Münzen lassen zum
                    Teil bis heute zurückverfolgen.
                    <br /><br />
                    Das Themengebiet der Sammlungsgeschichte ist ein eigenes Gebiet
                    der Numismatik.
                </div></div>

            <div className="flex pt-4 flex-col space-y-4"><h2 className="pt-6 text-gray-700 font-bold text-2xl">Was ist Numismatik?</h2>
                <div className=" text-left leading-6 text-lg text-gray-700 pt-4">
                    Der Definition nach ist die Numismatik der Fachbegriff für
                    Münzkunde. Die Numismatik ist die wissenschaftliche
                    Beschäftigung mit Geld und der Geschichte des Geldes.
                    <br /><br />
                    Die Numismatik beschäftigt sich nicht nur mit Münzen, sondern
                    auch beispielsweise mit vormünzlichen Zahlungsmitteln,
                    Medaillen, Papiergeld und weiteren Teilbereichen.
                </div></div>

            <div className="flex pt-4 flex-col space-y-4"><h2 className="pt-6 text-gray-700 font-bold text-2xl">
                Welche Kaiser sind auf römischen Münzen - Menschen oder Götter?
            </h2>
                <div className=" text-left leading-6 text-lg text-gray-700 pt-4">
                    Julius Caesar war der erste römische Herrscher, der sich auf
                    römischen Münzen darstellen ließ. Von Caesar Augustus, seinem
                    Adoptivsohn, an haben sich alle Kaiser in naturalistischen
                    Porträts auf römischen Münzen darstellen lassen.
                    <br /><br />
                    Anders ist es bei griechischen Münzen, die griechischen Könige
                    seit Alexander dem Großen (+323 v. Chr.) haben sich als
                    Vergöttlichte gezeigt, edel und schön. Auf antiken griechischen
                    Münzen findet man keinen Herrscher mit fettem Hals wie der
                    römische Kaiser Vespasian. Alexander der Große hat sich mit dem
                    Löwenfell des Herakles ums Haupt auf seinen Münzen abbilden
                    lassen, bartlos, jung und schön, wie man in Griechenland zuvor
                    schöne Menschen als Götter dargestellt hat.
                </div></div>

            <div className="flex pt-4 flex-col space-y-4"><h2 className="pt-6 text-gray-700 font-bold text-2xl"> Wofür steht das SC auf römischen Münzen? </h2>
                <div className=" text-left leading-6 text-lg text-gray-700 pt-4 ">
                    Die Inschrift "SC" auf römischen Münzen gibt es erst seit nach
                    der römischen Republik. Die Kaiser beanspruchten die Prägung von
                    Goldmünzen und Silbermünzen für sich. Bronzemünzen, die das
                    römische Alltagsleben bestimmten, wurden durch den ehrwürdigen
                    römischen Senat geprägt: Senatus Consultu.
                    <br /><br />
                    Das lateinische Senatus Consultu bedeutet auf deutsch übersetzt
                    "durch Senatsbeschluss".
                </div></div>

            <div className="flex pt-4 flex-col space-y-4"><h2 className="pt-6 text-gray-700 font-bold text-2xl"> Welche römischen Münzen sind selten? </h2>
                <div className=" text-left leading-6 text-lg text-gray-700 pt-4">
                    Je edler das Metall, umso seltener sind die altrömischen Münzen,
                    denn man hat sie bei Bedarf gern umgeprägt. Selten sind alle
                    Goldmünzen – sie waren mehr zur Hortung als für den täglichen
                    Geldverkehr gedacht. Gold floss auch verstärkt in den Fern- und
                    Asienhandel.
                    <br /><br />
                    Selten sind jedoch auch Münzen mit begrenzter Auflage wie die
                    vielfältigen und fantasievollen Denare des Kaisers Augustus.
                    Diese altrömischen Münzen sind besonders schön. Rar sind auch
                    die großen Bronzemünzen der Kaiserzeit, die Sesterzen (von Semis
                    tertius as = 2 ½ As, vier auf einen Silberdenar), weil sie viel
                    Metall enthalten und Bronze als Werkstoff immer wertvoll für
                    Diverses war.
                    <br /><br />
                    Hervorzuheben ist hier vor allem auch die Größe dieser Münze.
                    Der Sesterz war übrigens die Standardwährung, die Rechenmünze in
                    Rom.
                    <br /><br />
                    Grundsätzlich lässt sich sagen, dass auch selten ist, was
                    Sammler:innen als selten entdecken.
                    <br /><br />
                </div></div>

            <div className="flex pt-4 flex-col space-y-4"><h2 className="pt-6 text-gray-700 font-bold text-2xl"> Wie reinigt man antike Münzen? </h2>
                <div className=" text-left leading-6 text-lg text-gray-700 pt-4">
                    Antike Münzen sollte man idealerweise nicht reinigen. In der
                    Regel macht erst die Patina eine antike römische Münze richtig
                    schön und wertvoll.
                </div></div>

            <div className="flex pt-4 flex-col space-y-4"><h2 className="pt-6 text-gray-700 font-bold text-2xl"> Was ist eine Patina? </h2>

                <div className=" text-left leading-6 text-lg text-gray-700 pt-4">
                    Das Wort Patina kommt aus dem Italienischen und bedeutet
                    übersetzt so viel wie „dünne Schicht“. Gerade bei Münzen aus
                    Edelmetallen ist es eine durch natürliche Alterung entstandene
                    Oberfläche.
                </div></div>

            <div className="flex pt-4 flex-col space-y-4"><h2 className="pt-6 text-gray-700 font-bold text-2xl"> Wie kann man den Wert einer Münze ermitteln? </h2>
                <div className=" text-left leading-6 text-lg text-gray-700 pt-4">
                    Den Wert einer antiken Münze zu ermitteln ist oft selbst für
                    Expert:innen nicht ganz einfach. Die Recherche online oder in
                    Fachlektüre für Numismatik gibt nicht immer Aufschluss über
                    einzelne seltene antike Münzen und deren Wert. Unsere
                    Expert:innen für Numismatik in der Münzgalerie München helfen
                    Ihnen gerne bei Fragen rund um antike Münzen und weitere Gebiete
                    der Numismatik weiter.
                </div></div>

            <div className="flex pt-4 flex-col space-y-4"><h2 className="pt-6 text-gray-700 font-bold text-2xl"> Römische Münzen kaufen </h2>
                <div className=" text-left leading-6 text-lg text-gray-700 pt-4">
                    Sie wollen römische Münzen kaufen? Die Münzgalerie München ist
                    eine der führenden Adressen im Handel von antiken römischen
                    Münzen.
                    <br /><br />
                    Egal ob altrömische Münze oder spätrömische Münze, Goldmünze,
                    Silbermünze oder Bronzemünze. In unserem vielseitigen Sortiment
                    werden Sie bestimmt fündig. Wenn Sie etwas bestimmtes suchen,
                    sprechen Sie uns gerne an! Bei der Münzgalerie München wird
                    jeder Artikel auf seine Echtheit überprüft. Sie erhalten auf
                    Wunsch natürlich gerne ein Echtheitszertifikat. Bei uns kaufen
                    Sie ohne Risiko, garantiert günstig und immer zum besten Preis!
                    Mehr dazu finden Sie in unserem Onlineshop oder in unserem
                    Ladengeschäft in München.
                </div></div>

            <div className="flex pt-4 flex-col space-y-4"><h2 className="pt-6 text-gray-700 font-bold text-2xl"> Römische Münzen bestimmen </h2>
                <div className=" text-left leading-6 text-lg text-gray-700 pt-4">
                    Sie wollen römische Münzen bestimmen lassen? Unsere Expert:innen
                    für Numismatik in München helfen Ihnen gerne jederzeit beim
                    Bestimmen Ihrer antiken römischen Münzen oder weiterer Objekte
                    der Numismatik weiter. Besuchen Sie uns gerne in unserem
                    Ladengeschäft am Stiglmaierplatz 2 in München oder schicken Sie
                    uns eine E- Mail.
                </div></div>

            <div className="flex pt-4 flex-col space-y-4"><h2 className="pt-6 text-gray-700 font-bold text-2xl"> Römische Münzen verkaufen </h2>
                <div className=" text-left leading-6 text-lg text-gray-700 pt-4">
                    Sie wollen römische Münzen verkaufen? Die Münzgalerie München
                    ist eine der führenden Adressen im Ankauf und Verkauf von
                    antiken römischen Münzen.
                    <br /><br />
                    Jede Münze wird von unseren Numismatik Expert:innen auf ihre
                    Echtheit und ihren aktuellen Wert geprüft. So verkaufen Sie ihre
                    antike römische Münze garantiert zum besten Preis!
                </div></div>
        </div>
    );
}
