import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";
import { useTranslation } from "react-i18next";
import HalfImageHalfLinkDiv from "../components/HalfImageHalfLinkDiv";
import CarouselBar from "../components/CarouselBar";
import TitleBar from "../components/TitleBar";

export default function CatalogScreen(props) {
  const { t } = useTranslation(["catalog_screen"]);
  const carousel_items = [
    { image: '/images/buecher-crop.jpg', link: '', title: t("carousel1_h"), text: t("carousel1_t") }
  ]
  return (
    <div className="px-5p">
      <CarouselBar items={carousel_items} />
      <TitleBar title={t("title")} description={t("description")} />
      <div className="pt-10 flex flex-col space-y-10">
        <HalfImageHalfLinkDiv
          alignment={"right"}
          title="Intermünz-Kurier 193"
          image={"/images/kataloge/193.jpg"}
          link={"/pdf/catalogs/mgm_imk_193.pdf"}
          description={""}
        />
        <HalfImageHalfLinkDiv
          alignment={"left"}
          title="Weihnachts-Sonderliste 2023"
          image={"/images/kataloge/w2023.jpg"}
          link={"/pdf/catalogs/mgm_2023_sonderliste_weihnacht.pdf"}
          description={""}
        />
        <HalfImageHalfLinkDiv
          alignment={"right"}
          title="Intermünz-Kurier 192"
          image={"/images/kataloge/192.jpg"}
          link={"/pdf/catalogs/mgm_imk_192.pdf"}
          description={"Medaillen von Karl Goetz: Die Sammlung Leigh Park (Teil 3)"}
        />
        <HalfImageHalfLinkDiv
          alignment={"left"}
          title="Intermünz-Kurier 191"
          image={"/images/kataloge/191.jpg"}
          link={"/pdf/catalogs/mgm_imk_191.pdf"}
          description={"Münstersche Numismatische Zeitung - Antike Münzen - Goldmünzen - Altdeutschland - Reichsmünzen - Thematische Medaillen - Ausländische Medaillen - Ausländische Münzen - Banknoten"}
        />
        <HalfImageHalfLinkDiv
          alignment={"right"}
          title={t("catalog1_h")}
          image={"/images/kataloge/190.svg"}
          link={"/pdf/catalogs/mgm_IMK_190_goetz_part2.pdf"}
          description={t("catalog1_1") + " " + t("catalog1_2")}
        />
        <HalfImageHalfLinkDiv
          alignment={"left"}
          title={t("catalog2_h")}
          image={"/images/kataloge/189.svg"}
          description={<ul style={{ width: '100%' }}>
            <li className="py-4"><a href="/pdf/catalogs/mgm_IMK_189_muenzen_antike_roemer_griechen.pdf">{t("catalog2_1")}</a></li>
            <li className="py-4"><a href="/pdf/catalogs/mgm_IMK_189_goldmuenzen_reichsgoldmuenzen.pdf">{t("catalog2_2")}</a></li>
            <li className="py-4"><a href="/pdf/catalogs/mgm_IMK_189_goldmuenzen_goldmedaillen.pdf">{t("catalog2_3")}</a></li>
            <li className="py-4"><a href="/pdf/catalogs/mgm_IMK_189_altdeutsche_muenzen_medaillen.pdf">{t("catalog2_4")}</a></li>
            <li className="py-4"><a href="/pdf/catalogs/mgm_IMK_189_thematische_medaillen.pdf">{t("catalog2_5")}</a></li>
            <li className="py-4"><a href="/pdf/catalogs/mgm_IMK_189_reichsmuenzen_deutsche_muenzen.pdf">{t("catalog2_6")}</a></li>
            <li className="py-4"><a href="/pdf/catalogs/mgm_IMK_189_muenzen_ausland.pdf">{t("catalog2_7")}</a></li>
            <li className="py-4"><a href="/pdf/catalogs/mgm_IMK_189_banknoten_geldscheine.pdf">{t("catalog2_8")}</a></li>
          </ul>}
        />
        <HalfImageHalfLinkDiv
          alignment={"right"}
          title={t("catalog3_h")}
          image={"/images/kataloge/188.svg"}
          link={"/pdf/catalogs/mgm_IMK_188_medaillen_karl_goetz_teil01.pdf"}
          description={t("catalog3_1") + " " + t("catalog3_2")}
        />
        <HalfImageHalfLinkDiv
          alignment={"left"}
          title={t("catalog5_h")}
          image={"/images/kataloge/lit2017.svg"}
          link={"/pdf/catalogs/mgm_literatur_2017.pdf"}
        />
      </div>
    </div>
  );
}
