import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TitleBar from "../components/TitleBar";
import CarouselBar from "../components/CarouselBar";
import Question from "../components/Question";
// import AccordionBox from "../components/AccordionBox";

export default function GoldSaleScreen(props) {
  const { t } = useTranslation(["gold_sale_screen"]);
  const carousel_items = [
    {
      image: "/images/head-gold-1-crop.jpg",
      link: "",
      title: t("carousel1_h"),
      text: t("carousel1_t"),
    },
  ];

  const gold_ref = React.useRef(null)

  const investments = [
      {title: t("invest1_h"),text: t("invest1_t"), ref: gold_ref},
      {title: t("invest2_h"),text: t("invest2_t")},
      {title: t("invest3_h"),text: t("invest3_t")}
  ]

  useEffect(() => {
    gold_ref.current.click()
  }, [])

  return (
    <div className="px-5p">
      <CarouselBar items={carousel_items} />
      <TitleBar title={t("title")} description={t("description")} />
      <div className='space-y-3'>
        {investments.map((piece, idx) => <Question id={idx} question={piece.title} answer={piece.text} rref={piece.ref} />)}
      </div>
    </div>
  )
}