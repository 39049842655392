import Axios from 'axios';
import { GET_COIN_FAIL, GET_COIN_REQUEST, GET_COIN_SUCCESS,} from '../constants/shopCoinConstants';

export const getCurrentShopCoins = () => async (dispatch, getState) => {
    dispatch({ type: GET_COIN_REQUEST });
    try {
        const {data} = await Axios.get('//shop.muenzgalerie.de/api/products/wikisearch_category/Muenzen');
        dispatch({type: GET_COIN_SUCCESS, payload: data});

    } catch (error) {
        dispatch({
            type: GET_COIN_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};

