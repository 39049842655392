import React from "react";

export default function TitleBar(props) {
  return (
    <div className="h-full flex items-center flex-col space-y-2 place-content-center py-10 space-y-2">
      <div className="text-center  space-y-4 divide-y flex flex-col">
        <h1 className="text-2xl md:text-3xl uppercase text-gray-700 tracking-superwide font-bold">
          {props.title}
        </h1>
        <span
          className="whitespace-pre-line text-left italic text-lg text-gray-700 pt-4"
          dangerouslySetInnerHTML={{
            __html: props.description,
          }}
        >
        </span>
      </div>
    </div>
  );
}
