import React from "react";
import { useTranslation } from "react-i18next";

export default function TermsScreen() {
  const { t } = useTranslation(["terms_screen"]);
  // Schema: id= Paragrafnummer, l = Länge bzw. Anzahl der Nummern, s = Subparagraf Liste: Entweder null oder [Index= Bei welcher Nummer gibt es Subparagrafen?, Länge der Subparagrafen]
  const order = [
    { id: 1, l: 3, s: null },
    { id: 2, l: 5, s: [4, 7] },
    { id: 3, l: 3, s: null },
    { id: 4, l: 3, s: null },
    { id: 5, l: 3, s: null },
    { id: 6, l: 1, s: null },
    { id: 7, l: 4, s: [4, 5] },
    { id: 8, l: 1, s: [1, 2] },
    { id: 9, l: 2, s: [1, 4] },
    { id: 10, l: 2, s: null },
    { id: 11, l: 1, s: null },
    { id: 12, l: 1, s: null },
    { id: 13, l: 1, s: null },
    { id: 14, l: 1, s: null },
  ];
  return (
    <div className="px-5p">
      <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">
        {t("agb_h")}
      </h1>
      <div className="space-y-4 divide-y">
        {order.map((entry, idx) => {
          return (
            <div className="space-y-2" key={"c"+idx}>
              <b>
                {"§" + entry["id"] + " " + t("paragraph" + entry["id"] + "_h")}
              </b>
              <div className="text-sm space-y-2">
                {[...Array(parseInt(entry["l"]))].map((e, idx2) => {
                  if (entry["s"] && entry["s"][0] === idx2 + 1) {
                    return (
                      <div className="space-y-2" key={"p"+idx2}>
                        <div>
                          {idx2 +
                            1 +
                            ") " +
                            t("paragraph" + entry["id"] + "_" + (idx2 + 1))}
                        </div>
                        <div className="pl-10 space-y-2">
                          {[...Array(parseInt(entry["s"][1]))].map(
                            (e, sub_index) => {
                              return (
                                <div
                                  key={"sp"+sub_index}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      "- " +
                                      t(
                                        "paragraph" +
                                          entry["id"] +
                                          "_" +
                                          (idx2 + 1) +
                                          "_" +
                                          (sub_index + 1)
                                      ),
                                  }}
                                />
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={"p"+idx2}>
                        {(parseInt(entry["l"]) === 1 ? "" : idx2 + 1 + ") ") +
                          t("paragraph" + entry["id"] + "_" + (idx2 + 1))}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
