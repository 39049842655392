import { GET_COIN_FAIL, GET_COIN_REQUEST, GET_COIN_SUCCESS, GET_COIN_RESET } from '../constants/shopCoinConstants';

export const shopCoinReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_COIN_REQUEST:
            return { loading: true };
        case GET_COIN_SUCCESS:
            return { loading: false, success: true, coins: action.payload };
        case GET_COIN_FAIL:
            return { loading: false, error: action.payload };
        case GET_COIN_RESET:
            return {};
        default:
            return state;
    }
};