import React from "react";
import { useRef, useEffect, useState } from "react";
import "../css/timeline.css";

export default function Timeline(props) {
  const { title, subtitle, items } = props;
  const itemsRef = useRef([]); //taken from https://stackoverflow.com/questions/54633690/
  const timelineRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, props.items.length);
  }, [props.items]);

  
  const listenScrollEvent = (event) => {
      let pos = event.target.scrollTop
      let min = itemsRef.current[activeIndex].offsetTop
      let height = itemsRef.current[activeIndex].scrollHeight
      let max = height + min
      if (pos >= max && pos >= min) {
        setActiveIndex(activeIndex + 1)
      } else if (pos <= min - 150 && activeIndex > 0){
        setActiveIndex(activeIndex - 1)
      }
  }
  
  return (
    <div className="h-screen overflow-x-scroll timeline-scrolldiv" onScroll={listenScrollEvent} >
    <div className="timeline-container" id="timeline-1" style={{backgroundImage: "url("+props.items[activeIndex].image+")"}}>
      <div className="timeline-header">
        <h2 className="timeline-header__title">{title}</h2>
        <h3 className="timeline-header__subtitle">{subtitle}</h3>
      </div>
      <div className="timeline" ref={timelineRef} >
          {items.map((item, idx) => {
              return (<div className={idx === activeIndex ? "timeline-item timeline-item--active": "timeline-item"} data-text={item.year} key={idx} ref={el => itemsRef.current[idx] = el}>
                  <div className="timeline__content">
                      <img className="timeline__img" src={item.image} alt={item.image} />
                      <h2 className="timeline__content-title">{item.title}</h2>
                      <p className="timeline__content-desc">{item.description}</p>
                  </div>
              </div>)
          })}
      </div>
    </div>
    </div>
  );
}
