import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";
import { useTranslation } from "react-i18next";
import TitleBar from "../components/TitleBar";
import CarouselBar from "../components/CarouselBar";

export default function AboutUs(props) {
  const { t } = useTranslation(["about_us_screen"]);
  const carousel_items = [
    {image: '/images/head-trachten-crop.jpg', link: '', title: t("carousel1_h"), text: t("carousel1_t")}
  ]
  return (
    <div className="px-5p">
      <CarouselBar items={carousel_items}/>
      <TitleBar title={t("about_us_h")}/>
      <div className="divide-y space-y-2">
        <div className="pb-2">
          <p>{t("about_us_t")}</p>
        </div>
      </div>
    </div>
  );
}
