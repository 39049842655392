import React from "react";
import "../css/accordion.css";

export default function UnknownPiece(props) {
  const { title, image, question_text, solved, solution_text, ref } =
    props.commonProps;
  return (
    <div className="acctab">
      <input type="checkbox" className="acc-input" id={title} />
      <label
        className={solved ? "acctab-label acctab-bar solved" : "acctab-label acctab-bar unsolved"}
        htmlFor={title}
        ref={ref ? ref : null}
      >
        {title}
      </label>
      <div className="acctab-content container ">
        <div className="md:flex-row flex flex-col">
          <div className="md:w-1/3">
            <img src={image} className="img-responsive img-thumbnail" alt=""/>
          </div>
          <div className="md:w-2/3">
            <p className="seth">{question_text}</p>
          </div>
        </div>
        <br />
        <div className={solved ? "acctab-bar solved" : "acctab-bar unsolved"}>
          {solved ? (
            <p className="seth">{solution_text}</p>
          ) : (
            <p>Noch nicht gelöst</p>
          )}
        </div>
      </div>
    </div>
  );
}
