import React from "react";
import { NavLink } from "react-router-dom";

export default function NavbarParent(props) {
  return (
    <div>
      <div className="px-3 py-2 flex items-center text-xs uppercase">
        <span className="w-full md:w-auto dropdown md:hover:bg-gray-100 md:px-3 md:py-2 rounded transition duration-300 ease-in-out">
          {props.external ? (
            <a href={props.link}>{props.title}</a>
          ) : (
            <NavLink
              to={props.link}
              activeClassName="active-link"
              style={props.children && props.children.length > 0 ? { pointerEvents: "none" } : null} //disable click if menuitem has children
            >
              {props.title}
            </NavLink>
          )}
          {props.children && (
            <ul className="dropdown-menu relative md:absolute hidden text-gray-700 md:pt-5 z-50 md:-ml-3">
              {props.children}
            </ul>
          )}
        </span>
      </div>
    </div>
  );
}
