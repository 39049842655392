import { USER_CONTACT_FORM_FAIL, USER_CONTACT_FORM_RESET, USER_CONTACT_FORM_REQUEST, USER_CONTACT_FORM_SUCCESS } from "../constants/contactConstants";

export const userContactFormReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_CONTACT_FORM_REQUEST:
            return { loading: true };
        case USER_CONTACT_FORM_SUCCESS:
            return { loading: false, success: true};
        case USER_CONTACT_FORM_FAIL:
            return { loading: false, error: action.payload };
        case USER_CONTACT_FORM_RESET:
            return {};
        default:
            return state;
    }
};