import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";
import { useTranslation } from "react-i18next";
import TitleBar from "../components/TitleBar";
import CarouselBar from "../components/CarouselBar";

export default function TreasureTroveScreen(props) {
    const { t } = useTranslation(["treasure_trove_screen"]);
    const carousel_items = [
      {image: '/images/fundgr-crop.jpg', link: '', title: t("carousel1_h"), text: t("carousel1_t")}
    ]    
    return (
      <div className="px-5p">
        <CarouselBar items={carousel_items} />
        <TitleBar title={t("title")} description={t("description")}/>
      </div>
    )
  }