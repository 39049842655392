import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";
import { useTranslation } from "react-i18next";
import TitleBar from "../components/TitleBar";
import CarouselBar from "../components/CarouselBar";
import Timeline from "../components/Timeline";

export default function PlayingCardsScreen(props) {
  const { t } = useTranslation(["playing_cards_screen"]);
  const timeline_items = [...Array(6)].map((e, i) => {
    if (i === 3 || i === 4) {
      return {
        title: t("timeline" + (i + 1) + "_title"),
        description: t("timeline" + (i + 1) + "_text"),
        image: "/images/timelines/playing_cards/3.jpg",
      };
    } else {
      return {
        title: t("timeline" + (i + 1) + "_title"),
        description: t("timeline" + (i + 1) + "_text"),
        image: "/images/timelines/playing_cards/" + (i + 1) + ".jpg",
      };
    }
  });
  const carousel_items = [
    {
      image: "/images/head-spielkarten-crop.jpg",
      link: "",
      title: t("carousel1_h"),
      text: t("carousel1_t"),
    },
  ];
  return (
    <div className="px-5p">
      <CarouselBar items={carousel_items} />
      <TitleBar title={t("title")} description={t("description")} />
      <div className="pb-2">
        <p className="font-semibold py-5">{t("cards1_h")}</p>
        <p>{t("cards1_1")}</p>
        <p>{t("cards1_2")}</p>
      </div>
      <div className="divide-y space-y-2">
        <div className="w-full items-center justify-center flex flex-col md:flex-row ">
          <div className="md:w-full justify-center flex flex-col items-center p-4 h-full space-y-2 md:space-y-5 md:p-10">
            <div className="text-center w-full tracking-widest text-2xl md:text-3xl uppercase font-semibold">
              {t("timeline_h")}
            </div>
            <div className="whitespace-pre-line">{t("timeline_t")}</div>
          </div>
        </div>
      </div>
      <Timeline
        title={t("timeline_h")}
        description=""
        items={timeline_items}
      />
    </div>
  );
}
