import React from "react";
import { useTranslation } from "react-i18next";
import currencyFormat from "../utils/currency";

export default function ProductBox(props) {
  const { t } = useTranslation(["product"]);

  const { product } = props;
  return (
    <a href ={"//shop.muenzgalerie.de/product/"+product._id}>
      <li key={product._id} className="h-full  text-sm">
        <div
          className={`product border border-gray-200 flex bg-white flex-nowrap flex-col h-full ${
            props.width && `md:w-${props.width}`
          } ${
            props.mobileWidth && `w-${props.mobileWidth}`
          } justify-between py-5 px-5`}
        >
          <div className="flex flex-row justify-between pb-2">
            <div className="font-semibold text-base text-right">
              {product.stock === 0 ? (
                <span className="text-red-500">
                  {t("soldout", "Ausverkauft")}
                </span>
              ) : (
                currencyFormat(product.price)
              )}
            </div>
          </div>
          <div className="flex flex-row flex-nowrap justify-items-center justify-center	items-center">
            <img
              className="w-auto md:max-w-full"
              src={"//shop.muenzgalerie.de"+product.image[0].replace('../','/')}
              alt={product.category}
              onMouseOver={
                product.image.length > 1
                  ? (e) =>
                      (e.currentTarget.src = "//shop.muenzgalerie.de"+product.image[1].replace('../','/'))
                  : undefined
              }
              onMouseOut={
                product.image.length > 1
                  ? (e) =>
                      (e.currentTarget.src = "//shop.muenzgalerie.de"+product.image[0].replace('../','/'))
                  : undefined
              }
            />
          </div>
          <div>
            <div className="">
              <div className="font-semibold">{product.brand}</div>
              <div className="">{product.name}</div>
            </div>
          </div>
        </div>
      </li>
    </a>
  );
}
