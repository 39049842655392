import Axios from 'axios';
import { GET_METAL_FAIL, GET_METAL_REQUEST, GET_METAL_SUCCESS,} from '../constants/metalConstants';

export const getCurrentMetal = () => async (dispatch, getState) => {
    dispatch({ type: GET_METAL_REQUEST });
    try {
        const {data} = await Axios.get('//shop.muenzgalerie.de/api/metals/');
        dispatch({type: GET_METAL_SUCCESS, payload: data});

    } catch (error) {
        dispatch({
            type: GET_METAL_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};

