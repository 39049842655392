import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";
import { useTranslation } from "react-i18next";
import TitleBar from "../components/TitleBar";
import TableComponent from "../components/TableComponent";
import CarouselBar from "../components/CarouselBar";

export default function SpecialistArticles(props) {
  const { t } = useTranslation(["specialist_articles_screen"]);
  const carousel_items = [
    {
      image: "/images/buecher-crop.jpg",
      link: "",
      title: t("carousel1_h"),
      text: t("carousel1_t"),
    },
  ];
  return (
    <div className="px-5p">
      <CarouselBar items={carousel_items} />
      <TitleBar title={t("title")} description={t("description")} />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-1 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("col1")}
                    </th>
                    <th
                      scope="col"
                      className="px-1 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("col2")}
                    </th>
                    <th
                      scope="col"
                      className="px-1 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("col3")}
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">PDF</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  <TableComponent
                    roman={"XLVI/1"}
                    date={"Juli 2016"}
                    autor={"Feldmann, Achim"}
                    artikel={
                      "In arte voluptas - Medaillen und Abzeichen der Schlaraffia"
                    }
                    pdf={
                      "/pdf/specialist_articles/mgm_numismatischer_artikel_imk180_schlaraffia.pdf"
                    }
                  />
                  <TableComponent
                    roman={"XLV/3"}
                    date={"Dezember 2015"}
                    autor={"Feldmann, Achim"}
                    artikel={
                      "Billetes con Firma del Che - Die kubanischen Banknotenausgaben von 1960 und 1961."
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk179.pdf"}
                  />
                  <TableComponent
                    roman={"XLV/2"}
                    date={"Juni 2015"}
                    autor={"Feldmann, Achim"}
                    artikel={
                      "Vom Jagen und Finden - Eine Medaille auf das Denkmal für Dietrich Eckart in der Gemeinde Bichl (Oberbayern), gestaltet 1934 von dem Bildhauer Karl May"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk178.pdf"}
                  />
                  <TableComponent
                    roman={"XLV/1"}
                    date={"Januar 2015"}
                    autor={"Feldmann, Achim"}
                    autor2={"Hochstrasser, Gerhardt"}
                    artikel={
                      "Die Heiligtumsfahrt in Aachen und in Kornelimünster (Teil 2)"
                    }
                    artikel2={
                      "Privatmünzen der indischen Kaufleute in Mekka im 19. Jahrhundert?"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk177.pdf"}
                  />
                  <TableComponent
                    roman={"XLIV/1"}
                    date={"Juni 2014"}
                    autor={"Feldmann, Achim"}
                    autor2={"Hochstrasser, Gerhardt"}
                    artikel={
                      "Die Heiligtumsfahrt in Aachen und in Kornelimünster (Teil 1)"
                    }
                    artikel2={
                      "Zur Identität des Hermannstädter Münzers Marcus mit dem ungarischen Landesober- bzw. Kremnitzer Münzkammergrafen Marcus - Studien zur siebenbürgischen Numismatik des 15. Jahrhunderts, Teil 3"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk176.pdf"}
                  />
                  <TableComponent
                    roman={"XLIII/2"}
                    date={"Oktober 2013"}
                    autor={"Feldmann, Achim"}
                    autor2={"Hochstrasser, Gerhardt"}
                    artikel={"Die Münstersche Numismatische Zeitung"}
                    artikel2={
                      "Der Münzkammergraf Christophorus Italicus de Florencia in zeitgenössischen Urkunden"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk175.pdf"}
                  />
                  <TableComponent
                    roman={"XLIII/1"}
                    date={"Januar 2013"}
                    autor={"Feldmann, Achim"}
                    autor2={"Hochstrasser, Gerhardt"}
                    artikel={"U-Bahn fahren in China"}
                    artikel2={
                      "Anton und Thomas Trautenberger als Hermannstädter Münzkammergrafen"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk174.pdf"}
                  />
                  <TableComponent
                    roman={"XLII/1"}
                    date={"Juni 2012"}
                    autor={"Pesditschek, Martina und Feldmann, Achim"}
                    artikel={"Die Josef-Keil-Medaille 1958 von Arnold Hartig"}
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk173.pdf"}
                  />
                  <TableComponent
                    roman={"XLI/2"}
                    date={"November 2011"}
                    autor={"Feldmann, Achim und Schulze, Manfred"}
                    autor2={"Hochstrasser, Gerhardt"}
                    artikel={
                      "Maximilian Dasio - Weitere Ergänzungen zu seinem Medaillenwerk"
                    }
                    artikel2={
                      "Was gab es im mittelalterlichen Siebenbürgen: Gulden oder Dukaten?"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk172.pdf"}
                  />
                  <TableComponent
                    roman={"XLI/1"}
                    date={"März 2011"}
                    autor={"Sienell, Stefan und Feldmann, Achim"}
                    artikel={
                      "Die Hans-Horst-Meyer-Medaille der Akademie der Wissenschaften in Wien"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk171.pdf"}
                  />
                  <TableComponent
                    roman={"XL/1"}
                    date={"August 2010"}
                    autor={"Lierath, Friedhelm und Werner, Klaus"}
                    artikel={
                      "RAPID-PROTOTYPING - Eine neue Reproduktionstechnik und ihre Anwendung in der plastischen Kunst zur Vervielfältigung von Büsten und Medaillen"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk170.pdf"}
                  />
                  <TableComponent
                    roman={"XXXIX/3"}
                    date={"November 2009"}
                    autor={"Feldmann, Achim und Schulze, Manfred"}
                    artikel={
                      "Gesichtslos? Eine ungewöhnliche luxemburgische Münze des 14. Jahrhunderts"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk169.pdf"}
                  />
                  <TableComponent
                    roman={"XXXIX/2"}
                    date={"Juni 2009"}
                    autor={"Feldmann, Achim"}
                    autor2={
                      "Werner, Klaus (unter Mitarbeit von Achim Feldmann)"
                    }
                    artikel={
                      "Im Gleichschritt zum Gebet - Die internationale Soldatenwallfahrt nach Lourdes (2)"
                    }
                    artikel2={
                      "Medaillen, Abzeichen und Erinnerungsstücke auf Otto von Guericke (Teil 7)"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk168.pdf"}
                  />
                  <TableComponent
                    roman={"XXXIX/1"}
                    date={"Februar 2009"}
                    autor={"Feldmann, Achim"}
                    autor2={
                      "Werner, Klaus (unter Mitarbeit von Achim Feldmann)"
                    }
                    artikel={
                      "Im Gleichschritt zum Gebet - Die internationale Soldatenwallfahrt nach Lourdes"
                    }
                    artikel2={
                      "Medaillen, Abzeichen und Erinnerungsstücke auf Otto von Guericke (Teil 6)"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk167.pdf"}
                  />
                  <TableComponent
                    roman={"XXXVIII/2"}
                    date={"September 2008"}
                    autor={"Feldmann, Achim"}
                    autor2={
                      "Werner, Klaus (unter Mitarbeit von Achim Feldmann)"
                    }
                    artikel={
                      "Kleine Marke, großes Zelt - Eine Biermarke des Festzeltbetriebes Heimer aus München"
                    }
                    artikel2={
                      "Medaillen, Abzeichen und Erinnerungsstücke auf Otto von Guericke (Teil 5)"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk166.pdf"}
                  />
                  <TableComponent
                    roman={"XXXVIII/1"}
                    date={"Mai 2008"}
                    autor={"Werner, Klaus (unter Mitarbeit von Achim Feldmann)"}
                    artikel={
                      "Medaillen, Abzeichen und Erinnerungsstücke auf Otto von Guericke (Teil 4)"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk165.pdf"}
                  />
                  <TableComponent
                    roman={"XXXVII/3"}
                    date={"Dezember 2007"}
                    autor={"Werner, Klaus (unter Mitarbeit von Achim Feldmann)"}
                    artikel={
                      "Medaillen, Abzeichen und Erinnerungsstücke auf Otto von Guericke (Teil 3)"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk164.pdf"}
                  />
                  <TableComponent
                    roman={"XXXVII/2"}
                    date={"August 2007"}
                    autor={"Werner, Klaus (unter Mitarbeit von Achim Feldmann)"}
                    artikel={
                      "Medaillen, Abzeichen und Erinnerungsstücke auf Otto von Guericke (Teil 2)"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk163.pdf"}
                  />
                  <TableComponent
                    roman={"XXXVII/1"}
                    date={"März 2007"}
                    autor={"Werner, Klaus (unter Mitarbeit von Achim Feldmann)"}
                    artikel={
                      "Medaillen, Abzeichen und Erinnerungsstücke auf Otto von Guericke (Teil 1)"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk162.pdf"}
                  />
                  <TableComponent
                    roman={"XXXVI/3"}
                    date={"Oktober 2006"}
                    autor={"Feldmann, Achim"}
                    artikel={
                      "Des Kaisers neue Flieger - Unbekannte deutsche Luftfahrt-Medaillen (Teil 5)"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk161.pdf"}
                  />
                  <TableComponent
                    roman={"XXXVI/2"}
                    date={"Juni 2006"}
                    autor={"Feldmann, Achim"}
                    artikel={
                      "Buchonia, Herquet oder Hinkelbein? Numismatische Literaturzitate für Fulda"
                    }
                    artikel2={
                      "Des Kaisers neue Flieger - Unbekannte deutsche Luftfahrt-Medaillen (Teil 4)"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk160.pdf"}
                  />
                  <TableComponent
                    roman={"XXXVI/1"}
                    date={"März 2006"}
                    autor={"Feldmann, Achim"}
                    artikel={
                      "Des Kaisers neue Flieger - Unbekannte deutsche Luftfahrt-Medaillen (Teil 3)"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk159.pdf"}
                  />
                  <TableComponent
                    roman={"XXXV/3"}
                    date={"September 2005"}
                    autor={"Feldmann, Achim"}
                    artikel={
                      "Des Kaisers neue Flieger - Unbekannte deutsche Luftfahrt-Medaillen (Teil 2)"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk158.pdf"}
                  />
                  <TableComponent
                    roman={"XXXV/2"}
                    date={"Mai 2005"}
                    autor={"Feldmann, Achim"}
                    artikel={
                      "Des Kaisers neue Flieger - Unbekannte deutsche Luftfahrt-Medaillen (Teil 1)"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk157.pdf"}
                  />
                  <TableComponent
                    roman={"XXXV/1"}
                    date={"Februar 2005"}
                    autor={"Feldmann, Achim und Binder, Sylvia"}
                    artikel={
                      "Adolf von Mayrhofer, ein bekannter Münchner Schmuckkünstler"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk156.pdf"}
                  />
                  <TableComponent
                    roman={"XXXIV/3"}
                    date={"Oktober 2004"}
                    autor={"Funk, Annette"}
                    autor2={"Feldmann, Achim"}
                    artikel={"Pecunia non olet - oder???"}
                    artikel2={
                      "Faschings- und Karnevalsorden - Eine Einführung (Teil 2)"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk155.pdf"}
                  />
                  <TableComponent
                    roman={"XXXIV/2"}
                    date={"Juli 2004"}
                    autor={"Feldmann, Achim"}
                    artikel={
                      "Faschings- und Karnevalsorden - Eine Einführung (Teil 1)"
                    }
                    pdf={"/pdf/specialist_articles/mgm_numismatischer_artikel_imk154.pdf"}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
