// Ensure Protection: use ReCaptacha, limit file size, rename file name, whitelist png jpg heic, etc. image files,
// use axios post
// or: use email.js https://javascript.plainenglish.io/how-to-build-a-contact-form-in-react-that-sends-emails-using-emailjs-70011d2563a3
//Node Mail.js an sich selber schicken E-Mail Helper, node mailer,

import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import OpeningHours from "../components/OpeningHours";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import TitleBar from "../components/TitleBar";
import { sendContactForm } from "../actions/contactActions";
import { USER_CONTACT_FORM_RESET } from "../constants/contactConstants";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const mgm_location = { lat: 48.14776999999999, lng: 11.559349999999995 };
const maps_style = { width: "100%", height: "400px" };

export default function ContactScreen() {
  const { t } = useTranslation(["contact"]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyD2Et64dY84wz4FQXkyTuhlUf0QVrkZ51Q",
  });

  const userContactForm = useSelector((state) => state.userContactForm);
  const { success, error, loading } = userContactForm;
  const dispatch = useDispatch();
  const reRef = useRef();

  const submitForm = async (event) => {
    event.preventDefault();
    const token = await reRef.current.executeAsync();
    reRef.current.reset();

    dispatch(
      sendContactForm({
        title: event.target.title.value,
        username: event.target.username.value,
        address: event.target.address.value,
        message: event.target.message.value,
        attachment:
          event.target.attachment.files.length < 1
            ? null
            : await toBase64(event.target.attachment.files[0]),
        token: token,
      })
    );
  };

  useEffect(() => {
    dispatch({ type: USER_CONTACT_FORM_RESET });
  }, [dispatch]);

  return (
    <div className="px-5p ">
      <div className="block-px-5p">
        {isLoaded ? 
        (<GoogleMap
          center={mgm_location}
          zoom={15}
          mapContainerStyle={maps_style}
        >
          <Marker position={mgm_location} />
        </GoogleMap>) : <div/>}
      </div>
      <TitleBar title={t("contact_us", "Kontakt")} />
      <div className="py-3 flex flex-col w-full place-content-center md:flex-row justify-items-center space-y-4 md:space-y-0 md:space-x-4">
        <div className="items-center ">
          <OpeningHours></OpeningHours>
        </div>
        <div className="w-auto">
          {loading && <LoadingBox></LoadingBox>}
          {error && <MessageBox variant="danger">{error}</MessageBox>}
          {success && (
            <MessageBox variant="success">
              {t("message_sent", "Die Nachricht wurde erfolgreich abgesendet")}
            </MessageBox>
          )}
          <form onSubmit={submitForm} method="post">
            <div className="overflow-hidden rounded-md w-auto">
              <p>{t("contact_form_text")}</p>
              <div className="md:px-4 bg-white">
                <div className="grid grid-cols-2 gap-3 w-full md:w-auto md:gap-2">
                  <div className="col-span-6 md:w-auto">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name:
                    </label>
                    <input
                      type="text"
                      className="border px-2 h-7 mt-1 block w-full shadow-sm border-gray-300 rounded-md"
                      name="username"
                    />
                  </div>
                  <div className="col-span-6 md:w-auto">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("email", "E-Mail Adresse")}:
                    </label>
                    <input
                      type="text"
                      className="border px-2 h-7 mt-1 block w-full shadow-sm border-gray-300 rounded-md"
                      name="address"
                      required
                    />
                  </div>
                  <div className="col-span-6 md:w-auto">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("subject", "Betreff")}:
                    </label>
                    <input
                      type="text"
                      className="border px-2 h-7 mt-1 block w-full shadow-sm border-gray-300 rounded-md"
                      name="title"
                      required
                    />
                  </div>
                  <div className="col-span-6 md:w-auto">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("message", "Nachricht")}:
                    </label>
                    <textarea
                      className="border px-2 h-36 mt-1 block w-full shadow-sm border-gray-300 rounded-md"
                      name="message"
                      required
                    />
                  </div>
                  <div className="col-span-6 md:w-auto">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("file", "Bild")}:
                    </label>
                    <input type="file" accept="image/*" name="attachment" />
                  </div>
                </div>
                <div className="md:w-96 text-right pb-2">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {t("send_message", "Absenden")}
                  </button>
                  <ReCAPTCHA
                    ref={reRef}
                    sitekey="6Lc6d0UaAAAAAPtya1LLBJv5EuQP1kwv5EkLIG3G"
                    size="invisible"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
