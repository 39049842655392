import "react-responsive-carousel/lib/styles/carousel.min.css";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TitleBar from "../components/TitleBar";
import CarouselBar from "../components/CarouselBar";
import UnknownPiece from "../components/UnknownPiece";

export default function UnknownPieces(props) {
  const { t } = useTranslation(["unknown_pieces_screen"]);
  const carousel_items = [
    {image: '/images/muenzenold-crop.jpg', link: '', title: t("carousel1_h"), text: t("carousel1_t")}
  ]  
  const piece1_ref = React.useRef(null)
  const piece2_ref = React.useRef(null)

  useEffect(() => {
    piece1_ref.current.click()
    piece2_ref.current.click()
  }, [])

  const unknown_pieces = [
    {title: t("piece1_h"), image: "/images/unknown_pieces/unbekannt_069_01.jpg", question_text: t("piece1_q"), solved: false, ref: piece1_ref},
    {title: t("piece2_h"), image: "/images/unknown_pieces/unbekannt_070_01.jpg", question_text: t("piece2_q"), solved: false, ref: piece2_ref},
    {title: t("piece3_h"), image: "/images/unknown_pieces/unbekannt_025_01.jpg", question_text: t("piece3_q"), solved: true, solution_text: t("piece3_t")},
    {title: t("piece4_h"), image: "/images/unknown_pieces/unbekannt_63.jpg", question_text: t("piece4_q"), solved: true, solution_text: t("piece4_t")},
    {title: t("piece5_h"), image: "/images/unknown_pieces/unbekannt_064_01.jpg", question_text: t("piece5_q"), solved: true, solution_text: t("piece5_t")},
    {title: t("piece6_h"), image: "/images/unknown_pieces/unbekannt_065_01.jpg", question_text: t("piece6_q"), solved: true, solution_text: t("piece6_t")},
    {title: t("piece7_h"), image: "/images/unknown_pieces/unbekannt_067_01.jpg", question_text: t("piece7_q"), solved: true, solution_text: t("piece7_t")},
    {title: t("piece8_h"), image: "/images/unknown_pieces/unbekannt_068_01.jpg", question_text: t("piece8_q"), solved: true, solution_text: t("piece8_t")},
  ]


  return (
    <div className="px-5p">
      <CarouselBar items={carousel_items} />
      <TitleBar title={t("title")} description ={t("description")} />
      <div className='space-y-3'>
        {unknown_pieces.map((piece, idx) => <UnknownPiece key={idx} commonProps={piece} />)}
      </div>
      
    </div>
  )
}