import React from 'react'

export default function Question(props) {
    return (
        props.alternateStyle ? 
        <div className="rounded-t-lg tab w-full overflow-hidden  bg-gray-50 mb-1">
            <input type="checkbox" className="hidden" id={props.id} ref={props.rref ? props.rref : null}/>
            <label className="tab-owner flex items-center space-x-2 p-1 md:p-4 cursor-pointer border-b" htmlFor={props.id}>
                <div className="transition-all duration-500 font-bold md:text-xl plsbtn w-4 h-4 md:w-10 md:h-10 pb-1 flex justify-center items-center"></div>
                <div className="md:text-lg">{props.question}</div>
            </label>
            <div className="bg-gray-50 italic max-h-0 tab-content transition-all duration-300 whitespace-pre-line">
                {props.answer}
            </div>
        </div>
        :
        <div className="rounded-t-lg tab w-full overflow-hidden mb-1">
            <input type="checkbox" className="hidden" id={props.id} ref={props.rref ? props.rref : null}/>
            <label className="tab-owner flex items-center space-x-2 p-1 md:p-4 cursor-pointer border-b" htmlFor={props.id}>
                <div className="transition-all duration-500 font-bold md:text-xl plsbtn w-4 h-4 md:w-10 md:h-10 pb-1 flex justify-center items-center"></div>
                <div className="md:text-lg">{props.question}</div>
            </label>
            <div className="bg-gray-50 italic max-h-0 tab-content transition-all duration-300 whitespace-pre-line">
                {props.answer}
            </div>
        </div>
    )
}
