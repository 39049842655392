import React from "react";
import { Link } from "react-router-dom";

export default function CardTile(props) {
  const innerHtml = (
    <div className="h-full">
      <div className="transform hover:scale-105 transition duration-300 rounded-full shadow-xl w-full h-auto border">
        <img
          loading="lazy"
          className="w-full h-auto rounded-full"
          src={props.image}
          alt={props.title}
        />
      </div>
      <div className="flex flex-wrap px-2 py-10 justify-center space-y-3">
        <p className="font-semibold tracking-widest uppercase text-lg w-full text-center">
          {props.title}
        </p>
        <hr className="w-9/12" />
        <p className="transition duration-300 text-center text-gray-700 text-sm whitespace-pre-line">
          {props.description}
        </p>
      </div>
    </div>
  );
  return props.external ? (
    <a href={props.link} className="h-full">{innerHtml}</a>
  ) : (
    <Link to={props.link} className="h-full">{innerHtml}</Link>
  );
}
