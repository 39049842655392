import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from "react-ga";

export function useGoogleAnalytics() {
    const location = useLocation();

    useEffect(() => {
        const cookieValue = getCookieConsentValue();
        if (cookieValue === "true") {
            sendGA(location)
        } 
    }, [location])
}

export function sendGA(location) {
    ReactGA.initialize('UA-123836753-1', { cookie_flags: 'SameSite=None;Secure' })
    ReactGA.set({ anonymizeIp: true})
    ReactGA.pageview(location.pathname + location.search)
}
