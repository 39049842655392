import React from 'react'

export default function TableComponent(props) {
    return (
        <tr>
            <td className="px-1 md:px-6 md:py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="">
                        <div className="text-xs md:text-sm font-medium text-gray-900">
                        {props.roman}
                        </div>
                        <div className="text-xs md:text-sm text-gray-500">
                        {props.date}	
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-1 md:px-6 py-4 whitespace-nowrap">
                <div className="text-xs md:text-sm text-gray-900 whitespace-pre-line">{props.autor}</div>
                {props.autor2 && <div className="text-xs md:text-sm text-gray-900 whitespace-pre-line">{props.autor2}</div>}
            </td>
            <td className="px-1 md:px-6 py-4 whitespace-nowrap">
                <div className="text-xs md:text-sm text-gray-900 whitespace-pre-line">{props.artikel}</div>
                {props.artikel2 && <div className="text-xs md:text-sm text-gray-900 whitespace-pre-line">{props.artikel2}</div>}
            </td>
            <td className="px-1 md:px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <a className='pdffile' href={props.pdf} target="_blank" rel="noreferrer">PDF</a>
            </td>                            
        </tr>
                                    
    )
}
