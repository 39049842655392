import React from 'react'
import { Link } from 'react-router-dom'
import EmailAddress from '../components/EmailAddress'
import PhoneNumber from '../components/PhoneNumber'
import { useTranslation } from 'react-i18next'

export default function ImprintScreen() {
    const { t } = useTranslation(["imprint_screen"]);
    return (
        <div className="px-5p pb-5">
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">{t("title")}</h1>
            <div className="space-y-4 divide-y">
                <div className="space-y-2">
                    <div className="text-sm">
                        <p>Münzgalerie München MGM</p>
                        <p>Handelsgesellschaft mbH &amp; Co. Joker KG</p>
                        <p>Stiglmaierplatz 2</p>
                        <p>80333 München</p>
                        <p>Tel: <PhoneNumber number="+49895233660" text="(089) 52 33 660"/> / <PhoneNumber number="+49895234362" text="(089) 52 34 362"/></p>
                        <p>Fax: <PhoneNumber number="+498932795990" text="(089) 32 79 59 90"/></p>
                        <p>{t("represented")}: Münzgalerie München MGM Handelsgesellschaft mbH</p>
                        <br />
                        <p>E-Mail: <EmailAddress email="mgm@muenzgalerie.de"/></p>
                        <p>{t("mainpage")}: <Link className="underline" to="/">muenzgalerie.de</Link></p>
                        <br />
                        <p>{t("court")}</p>
                        <p>{t("court_nr")}: HRA 59787</p>
                        <br />
                        <p>{t("tax_nr")}: DE130006976</p>
                        <br />
                        <p>{t("partner")}:</p>
                        <p>Münzgalerie München MGM Handelsgesellschaft mbH</p>
                        <p>Stiglmaierplatz 2</p>
                        <p>80333 {t("munich")}</p>
                        <p>{t("director1")}</p>
                        <p>{t("director2")}</p>
                        <br />
                        <p>{t("court")}</p>
                        <p>{t("court_nr")}: HRB 49813</p>
                        <br />
                        <p>{t("responsable")} § 55 II RStV:</p>
                        <p>Susanne Benz</p>
                        <p>Stiglmaierplatz 2</p>
                        <p>80333 {t("munich")}</p>
                        <p>E-Mail: <EmailAddress email="mgm@muenzgalerie.de"/></p>
                        <p>Fax: <PhoneNumber number="+498932795990" text="(089) 32 79 59 90"/></p>
                        <p>Tel: <PhoneNumber number="+49895233660" text="(089) 52 33 660"/> / <PhoneNumber number="+49895234362" text="(089) 52 34 362"/></p>

                        <br />
                        <p><b>{t("liability_notice_title")}:</b></p>
                        <p>{t("liability_notice_text")}
                        </p>
                        <br />
                        <p><b>{t("online_dispute_title")}</b></p>
                        <p>{t("online_dispute_text")} <a className="underline" href="//ec.europa.eu/consumers/odr/">ec.europa.eu/consumers/odr/</a></p>
                        <br/>
                        <p>{t("gold_source")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
