import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import TitleBar from "../components/TitleBar";
import CarouselBar from "../components/CarouselBar";
import Question from "../components/Question";

export default function FAQScreen() {
  const { t } = useTranslation(["faq_screen"]);
  const [current_menuitem, setMenuitem] = useState(0)
  const carousel_items = [
    {
      image: "/images/buecher-crop.jpg",
      link: "",
      title: t("carousel1_h"),
      text: t("carousel1_t"),
    },
  ];
  const structure = [
    { title: "Münzschätzung", questions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
    {
      title: "Münzen verkaufen",
      questions: [16, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21],
    },
    { title: "Münzen kaufen", questions: [28, 29, 30, 31, 32, 33] },
    { title: "Schmuck", questions: [37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53]},
    { title: "Datenschutz", questions: [22, 23, 24] },
    { title: "Anfahrt", questions: [25, 26] },
    { title: "Zahlungsmöglichkeiten", questions: [34, 35] },
    { title: "Weiteres", questions: [27, 36] }
  ];
  return (
    <div className="px-5p">
      <CarouselBar items={carousel_items} />
      <TitleBar title={t("faq_h")} />
      <div className="divide-y space-y-2">
        <div className="flex flex-col md:flex-row">
          <nav className="flex-grow px-4 pb-4 md:block ">
            {structure.map((menuitem, idx) => (
              <button
                key={idx}
                className="block px-4 py-2 mt-2 text-base font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600  dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:outline-none focus:shadow-outline"
                style={current_menuitem === idx ? {backgroundColor: 'lightgray'} : {backgroundColor: 'white'}}
                onClick={() => setMenuitem(idx)}
              >
                {menuitem.title}
              </button>
            ))}
          </nav>
          <div className="pb-2">
            {structure[current_menuitem].questions.map(question => {
              return (
                <Question
                  key={question}
                  id={question}
                  question={t("faq" + question + "_h")}
                  answer={t("faq" + question + "_t")}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
