import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { userContactFormReducer } from "./reducers/contactReducer";
import { metalReducer } from "./reducers/metalReducers";
import { shopCoinReducer } from "./reducers/shopCoinReducer";

const initialState = {};
const reducer = combineReducers({
    metals: metalReducer,
    coins: shopCoinReducer,
    userContactForm: userContactFormReducer
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancer = compose;

const store = createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(thunk))
);

export default store;
