import Axios from "axios";
import { USER_CONTACT_FORM_FAIL, USER_CONTACT_FORM_REQUEST, USER_CONTACT_FORM_SUCCESS } from "../constants/contactConstants";

export const sendContactForm = (data) => async (dispatch, getState) => {
    dispatch({ type: USER_CONTACT_FORM_REQUEST });
    try {
        const { res } = await Axios.post(`/api/v1/sendMail`, data, {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'same-origin',
            crossDomain: true,
        });
        dispatch({ type: USER_CONTACT_FORM_SUCCESS, payload: res });
    } catch (error) {
        dispatch({
            type: USER_CONTACT_FORM_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        });
    }
};