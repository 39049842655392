import React from "react";
import { NavLink } from "react-router-dom";

export default function NavbarChild(props) {
  return (
    <li>
      {props.external ? (
        <a
          href={props.link}
          className="bg-gray-50 hover:bg-gray-200  transition duration-300 ease-in-out py-2 px-4 block normal-case tracking-wide text-sm"
        >
          {props.title}
        </a>
      ) : (
        <NavLink
          to={props.link}
          activeClassName="active-link"
          className="bg-gray-50 hover:bg-gray-200  transition duration-300 ease-in-out py-2 px-4 block normal-case tracking-wide text-sm"
        >
          {props.title}
        </NavLink>
      )}
    </li>
  );
}