import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentShopCoins } from "../actions/shopCoinActions";
import { GET_COIN_RESET } from "../constants/shopCoinConstants";
import ShopProductBar from "./ShopProductBar";
import { useTranslation } from 'react-i18next';

export default function ShopCoinWidget() {
  const { t } = useTranslation(["product_bar"]);
  const [muenzenState, setMuenzenDataState] = useState([]);
  const muenzen = useSelector((state) => state.coins);
  const { coins } = muenzen;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!coins) {
      dispatch(getCurrentShopCoins("Muenzen"));
      dispatch({ type: GET_COIN_RESET });
    } else {
      setMuenzenDataState(coins);
    }
  }, [dispatch, coins]);

  return (
  <ShopProductBar articleState={muenzenState} title={t("title_m")} link={'//shop.muenzgalerie.de/category/muenzen'}/>
  );
}
