import React from "react";
import { useTranslation } from "react-i18next";
import TitleBar from "../components/TitleBar";
import CarouselBar from "../components/CarouselBar";

export default function GoldPurchaseScreen(props) {
  const { t } = useTranslation(["gold_purchase_screen"]);
  const carousel_items = [
    {
      image: "/images/head-gold-1-crop.jpg",
      link: "",
      title: t("carousel1_h"),
      text: t("carousel1_t"),
    },
  ];

  return (
    <div className="px-5p">
      <CarouselBar items={carousel_items} />
      <TitleBar title={t("title")} description={t("description")} />
      <ul>
        {[...Array(9)].map((entry, idx) => {
          return (
            <li className="px-1 md:px-6 md:py-4 whitespace-nowrap" key={idx}>
              <h3 className="text-xl md:text-xl text-gray-900 whitespace-pre-line text-center">
                {t("buyItem" + (idx + 1))}
              </h3>
            </li>
          );
        })}
      </ul>
      <TitleBar description={t("description2")} />
    </div>
  );
}
