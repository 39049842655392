import React, { useRef } from "react";
import ProductBox from "./ProductBox";
import { useTranslation } from "react-i18next";

export default function ShopProductBar(props) {
  const { t } = useTranslation(["product_bar"]);
  const { articleState, title, link } = props;
  const productRef = useRef(null)

  const scrollLeft = () => {
    const scale = productRef.current.offsetWidth/2
    const scroll = productRef.current.scrollLeft
    productRef.current.scrollTo({left: scroll-scale, behavior: 'smooth'})
  }
  const scrollRight = () => {
    const scale = productRef.current.offsetWidth/2
    const scroll = productRef.current.scrollLeft
    productRef.current.scrollTo({left: scroll+scale, behavior: 'smooth'})
  }

  return (
    <div
      className="flex flex-col py-2 md:py-3 w-screen"
      style={{ width: "100%", position: 'relative'}}
    >
      <div className="font-semibold tracking-widest py-2 uppercase p-5">
        {title}
      </div>
      <div
        className="scrolling-wrapper bg-gray-100 list-none w-screen border p-5 border-dashed space-x-5"
        style={{ width: "100%", position: 'relative' }}
        ref={productRef}
      >
        {articleState &&
          articleState.map((artikel, idx) => (
            <ProductBox
              width="96"
              mobileWidth="60"
              key={idx}
              product={artikel}
            />
          ))}
      </div>
      <button
        onMouseDown={scrollLeft}
        className="flickity-button flickity-prev-next-button previous"
      >
        <svg className="flickity-button-icon" viewBox="0 0 100 100">
          <path
            d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
            className="arrow"
          ></path>
        </svg>
      </button>
      <button
        onMouseDown={scrollRight}
        className="flickity-button flickity-prev-next-button next"
      >
        <svg className="flickity-button-icon" viewBox="0 0 100 100">
          <path
            d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
            className="arrow"
            transform="translate(100, 100) rotate(180) "
          ></path>
        </svg>
      </button>
      <div className="flex justify-center md:justify-end p-2">
        <a
          href={link}
          className="transition duration-300 ease-in-out border p-2 w-52 text-center uppercase border-gray-300  text-blue-800  hover:border-gray-500 tracking-widest"
        >
          {t("discover")}
        </a>
      </div>
    </div>
  );
}
